.segmentInner {
	overflow: hidden;
	display: block;
}

.segmentlink {
	padding: 8px;
	display: block;
}

.segment {
	background-color: #364d79;
	position: absolute;
	color: white;
	list-style-type: none;
	padding: 0px;
}

.failedSegment {
	background-color: red;
	position: absolute;
	color: white;
	list-style-type: none;
	padding: 0px;
}