.tracks {
	border: 1px solid #f0f0f0;
	overflow: auto;
	white-space: nowrap;
	padding: 0px;
}

.sessionPage {
	overflow: hidden;
}

.zoomSlider {
	width: 100px;
}

.mpdItem {
	list-style-type: none;
}

.blockSmall {
	border: 1px solid #f0f0f0;
	position: relative;
	width: 200px;
	padding: 8px;
	margin-bottom: 16px;
}

.block {
	border: 1px solid #f0f0f0;
	padding: 8px;
	margin-bottom: 16px;
}

ul {
	list-style-type: none;
	padding: 0;
}
