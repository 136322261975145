/* colors
https://www.sessions.edu/color-calculator/
364e79
4b3679
367962
*/

.track {
	padding: 8px;
	margin-top: 8px;
	margin-bottom: 8px;
	margin-left: 0px;
	margin-right: 0px;
	position: relative;
	height: 80px;
	border: 1px solid #f0f0f0;
	width: 4000px;
}





.mpdReload {
	background-color: #367962;
	padding: 0px;
	position: absolute;
	color: white;
	list-style-type: none;
	z-index: 5;
	min-width: 10px;
	height: 48px;
}
